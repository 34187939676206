import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Button = styled.button`
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 1rem;
`;
