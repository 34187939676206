import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 300px;
  height: 300px;
  margin: 3vw;
  /* padding: 3vw; */

  font-size: clamp(12px, 1.2vh, 26px);

  background: #fff;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #323232;
  font-weight: 700;
  white-space: nowrap;
  svg {
    width: 20%;
    height: auto;
    margin-bottom: 5px;
  }

  :disabled {
    color: rgba(0, 0, 0, 0.25);
    background: rgba(0, 0, 0, 0.1);
    cursor: not-allowed;
  }
`;
